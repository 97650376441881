<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-18 11:12:28
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-23 11:24:17
-->
<template>
  <el-card header="修改密码" class="box-card" shadow="never" style="min-height:750px">
    <el-form
      :model="editPassForm"
      :rules="editPassFormRules"
      ref="editPassForm"
      label-width="100px"
      style="width:80%"
    >
      <el-form-item label="用户名" prop="name">
        <el-input v-model="editPassForm.name" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="旧密码" prop="password">
        <el-input type="password" v-model="editPassForm.password"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="resetPassword">
        <el-input type="password" v-model="editPassForm.resetPassword"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input type="password" v-model="editPassForm.checkPass"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('editPassForm')">提交</el-button>
        <el-button @click="resetForm('editPassForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import api from "@/api/list";
import { rsaEncrypt } from "@/utils/rsa";
import { mapGetters } from "vuex";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.editPassForm.checkPass !== "") {
          this.$refs.editPassForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.editPassForm.resetPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      editPassForm: {
        name: "",
        password: "",
        resetPassword: "",
        checkPass: "",
      },
      editPassFormRules: {
        password: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        resetPassword: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    this.editPassForm.name = this.user.name;
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          api
            .resetPassword(
              {
                password: rsaEncrypt(this.editPassForm.password),
                resetPassword: rsaEncrypt(this.editPassForm.resetPassword),
              },
              { method: "patch" }
            )
            .then((res) => {
              if (res.status == "200") {
                this.$message({
                  showClose: true,
                  message: "密码修改成功",
                  type: "success",
                });
              } else {
                this.$message({
                  showClose: true,
                  message: res.message,
                  type: "warning",
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.editPassForm.name = this.user.name;
    },
  },
};
</script>